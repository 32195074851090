import React, { useState, useEffect } from 'react'
import Project from "./project.component";
import ReactLoading from "react-loading";
import { useLocation } from 'react-router-dom';
import BASEURL from '../../constants/baseAddress.const'
import axios from "axios";

const AllPendingTasks = ({userId, setUserInfo, activeTab}) => {
    const [userStatus, setUserStatus] = useState(null);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const loadData = () => {

        axios.get(`${BASEURL}/Dashboard/GetPendingTaskByUserId/${userId ?? userId}`)
            .then((res) => {
                if (res.data.resultFlag) {
                    setIsDataLoaded(true);
                    setUserStatus(res.data.data);
                    const userData = {
                        avatarURL: res.data.data.avatarURL,
                        username: res.data.data.username
                    };
                    setUserInfo(userData);
                }
            });
    }


    useEffect(() => {
        if (activeTab === '1') {
            userId && loadData();
        }
    }, [userId, activeTab])

    return (
        <div className='p-5'>
            {
                isDataLoaded ?
                    <>
                        <div className='d-flex justify-content-between align-items-center'>

                            <div className='d-flex justify-content-between align-items-center'>

                                <p className='text-align-center font-size-24 montserrat-font-family mt-3'>
                                    Total Pending Biz: {userStatus?.totalPendingBiz}
                                </p>
                            </div>
                        </div>
                        <div className='mt-5'>
                            {
                                userStatus?.projects.map((project) => (
                                    <div key={project.projectId} className='mt-3'>
                                        <Project project={project} />
                                    </div>
                                ))
                            }
                        </div>
                    </>

                    :
                    <div className='text-center'>
                        <ReactLoading className='mx-auto' type='bars' color="593487" />
                    </div>
            }
        </div>
    )
}

export default AllPendingTasks;