import React, { useState, useEffect } from 'react';
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText, Row, Col } from "reactstrap";
import { useLocation } from 'react-router-dom';
import BASEURL from '../../constants/baseAddress.const'
import axios from "axios";
import Project from "../../components/users_pending_tasks/project.component";
import { Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Link } from 'react-router-dom'
import RoutesConst from "../../constants/routes.const";
import ReactLoading from "react-loading";
import classnames from 'classnames';
import AllPendingTasks from "../../components/users_pending_tasks/allPendingTasks.component";
import DailyTaskReport from "../../components/users_pending_tasks/dailyTaskReport.component";

const UsersPendingTasks = (props) => {
    const location = useLocation();
    const [userId, setUserId] = useState("");
    const [userInfo, setUserInfo] = useState({});


    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }


    useEffect(() => {
        const userId = location.pathname.substr(1);
        setUserId(userId);

    }, [])

    return (
        <div >
            <Container className='rounded bg-white mt-5 p-5'>
                <div>
                    <Link to={RoutesConst.MAIN}>
                        <Button
                            variant="contained"
                            startIcon={<ArrowBack />}
                            className='back-btn'>
                            Go back
                        </Button>
                    </Link>
                </div>
                <div className='d-flex justify-content-start'>
                    <div className='d-flex align-items-center'>
                        <img className='rounded-circle' src={userInfo?.avatarURL} alt={'User profile Image'}/>
                    </div>
                    <div className='d-flex align-items-center'>
                        <p className='font-size-24 montserrat-font-family ml-3 text-align-center mt-3'>
                            <strong>
                                {userInfo?.username}
                            </strong>
                        </p>
                    </div>
                </div>
                <div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1'); }}
                            >
                                Pending
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                            >
                                Daily Report
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <AllPendingTasks activeTab={activeTab} setUserInfo={setUserInfo} userId={userId} />
                        </TabPane>
                        <TabPane tabId="2">
                            <DailyTaskReport activeTab={activeTab} userId={userId} />
                        </TabPane>
                    </TabContent>
                </div>


            </Container>
        </div>
    )
}

export default UsersPendingTasks;