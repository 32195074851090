import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Task from "./task.component";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));


const Project = ({project}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = () => {
        setExpanded(!expanded);
    }

    const calculateTotalBiz = () => {
        let tbiz = 0;
        project.tasks.map((t) => {
            tbiz += t.bizCount;
        })

        return tbiz;
    }

    return (
        <div className={classes.root}>
            <Accordion expanded={expanded} onChange={handleChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <div className='d-flex justify-content-between w-100'>
                        <div className='d-flex justify-content-start'>
                            <Typography className='montserrat-font-family'>
                                <img src={project?.logoURL} className='w-100' alt='Project logo' style={{maxWidth: '24px'}}/>
                            </Typography>
                            <Typography className='ml-3 montserrat-font-family'>{project?.projectName}</Typography>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <Typography className='montserrat-font-family'>
                                Total tasks: {project?.total}
                            </Typography>
                            <Typography className='montserrat-font-family ml-3'>
                                Total biz: {calculateTotalBiz()}
                            </Typography>
                        </div>
                    </div>
                </AccordionSummary>

                    {
                        project?.tasks.map((task) => (
                            <AccordionDetails key={task.code} className='w-100'>
                                <div key={task.code} className='w-100'>
                                    <Task task={task} />
                                </div>
                            </AccordionDetails>
                        ))
                    }


            </Accordion>
        </div>
    )
}


export default Project;