import React, { useState, useEffect } from 'react'
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Task from "./task.component";
import Accordion from "@material-ui/core/Accordion";
import {makeStyles} from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const DailyTask = ({day}) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = () => {
        setExpanded(!expanded);
    }

    const dateToString = (dateTime) => {
        let targetDate = new Date(dateTime);

        return `${targetDate.getFullYear()}-${targetDate.getMonth()}-${targetDate.getDate()}`
    }

    return (
        <div className='mt-3'>
            <Accordion expanded={expanded} onChange={handleChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <div className='d-flex justify-content-between w-100'>
                        <div className='d-flex justify-content-start'>
                            <Typography className='ml-3 montserrat-font-family'>{dateToString(day?.date)}</Typography>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <Typography className='montserrat-font-family ml-3'>
                                Total biz: {day?.bizCount}
                            </Typography>
                        </div>
                    </div>
                </AccordionSummary>

                {
                    day?.tasks.map((task) => (
                        <AccordionDetails key={task.code} className='w-100'>
                            <div key={task.code} className='w-100'>
                                <Task task={task} />
                            </div>
                        </AccordionDetails>
                    ))
                }


            </Accordion>
        </div>
    )
}


export default DailyTask;