import React, { useState, useEffect } from 'react';
import axios from "axios";
import BASEURL from "../../constants/baseAddress.const";
import { TextField, Button} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ReactLoading from "react-loading";
import DailyTask from "./dailyTask.component";
import { Line } from "react-chartjs-2";


const DailyTaskReport = ({userId, activeTab}) => {

    const [userStatus, setUserStatus] = useState(null);
    // const [chartData, setChartData] = useState({});
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const loadData = (uId, sDate, eDate) => {
        let reqBody = {
            userId: uId,
            startDate: sDate,
            endDate: eDate
        }

        axios.post(`${BASEURL}/Dashboard/GetDailyReportForUser`, reqBody)
            .then((res) => {
                if (res.data.resultFlag) {
                    setIsDataLoaded(true);
                    setUserStatus(res.data.data);
                }
            });
    }

    useEffect(() => {
        // setting the default dates
        let defaultEndDate = new Date();
        setEndDate(`${defaultEndDate.getFullYear()}-${defaultEndDate.getMonth()}-${defaultEndDate.getDate()}`)
        let today = new Date();
        let defaultStartDate = new Date(today.setDate(today.getDate() - 7));
        setStartDate(`${defaultStartDate.getFullYear()}-${defaultStartDate.getMonth()}-${defaultStartDate.getDate()}`);
        if (activeTab === '2') {
            userId && loadData(userId, defaultStartDate, defaultEndDate);
        }
    }, [userId, activeTab])

    const submitForm = (e) => {
        e.preventDefault();
        setIsDataLoaded(false);
        loadData(userId, startDate, endDate);
    }

    const organizeChartData = () => {
        let labels = [];
        let dataArr = [];
        userStatus?.forEach((day) => {
            labels = [...labels, dateToString(day.date)];
            dataArr = [...dataArr, day.bizCount];
        });
        let data = {
            labels: labels.reverse(),
            datasets: [
                {
                    label: "Daily Biz Report",
                    data: dataArr.reverse(),
                    fill: true,
                    borderColor: "rgba(89,52,135,1)",
                    pointHoverRadius: 8,
                    pointHitRadius: 4
                },
            ]
        };
        return data;
    }

    const dateToString = (dateTime) => {
        let targetDate = new Date(dateTime);

        return `${targetDate.getFullYear()}-${targetDate.getMonth()}-${targetDate.getDate()}`
    }

    return (
        <div className='p-5'>
            <form onSubmit={(e) => submitForm(e)}>
                <div className='d-flex justify-content-start align-items-end'>
                    <div>
                        <TextField
                            id="date"
                            label="Start Date"
                            type="date"
                            className=''
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setStartDate(e.target.value)}
                            value={startDate}
                        />
                    </div>

                    <div className='ml-3'>
                        <TextField
                            id="date"
                            label="End Date"
                            type="date"
                            className=''
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setEndDate(e.target.value)}
                            value={endDate}
                        />
                    </div>
                    <div className='ml-3'>
                        <Button
                            startIcon={<SearchIcon />}
                            variant='outlined'
                            id='search-btn'
                            type='submit'
                            className=''
                        >
                            Search
                        </Button>
                    </div>
                </div>
            </form>
            <div className='mt-3'>
                {
                    isDataLoaded ?

                        <div>
                            <div>
                                <Line data={organizeChartData} />
                            </div>
                            {
                                userStatus?.map((day) => (
                                    <DailyTask day={day} key={day.date} />
                                ))
                            }
                        </div>
                        :
                        <div className='text-center'>
                            <ReactLoading className='mx-auto' type='bars' color="593487" />
                        </div>
                }
            </div>
        </div>
    )
}


export default DailyTaskReport;