import React from 'react';



const Task = ({task}) => {


    return (
        <div className='w-100'>
            <a href={task?.url} target="_blank" className='text-decoration-none text-black-50'>
                <div className='w-100 d-flex justify-content-between px-3 '>
                    <div>

                            <div className='d-flex justify-content-start'>
                                <p className='montserrat-font-family'>
                                    {task?.code}
                                </p>
                                <p className='montserrat-font-family ml-2'>
                                    {task?.summary}
                                </p>
                            </div>


                    </div>
                    <div>
                        <p className='montserrat-font-family'>
                            Biz: {task?.bizCount}
                        </p>
                    </div>
                </div>
            </a>
            <hr />
        </div>
    )
}


export default Task;