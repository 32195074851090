import React from 'react';
import RoutesConst from '../constants/routes.const';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import MainPage from "./main_page/main.page";
import UsersPendingTasks from './users_pending_tasks/usersTasks.page'


const Routes = () => {


    return (
        <Router>
            <Switch>
                <Route exact path={RoutesConst.MAIN}>
                    <MainPage />
                </Route>
                <Route path={`${RoutesConst.USER}`}>
                    <UsersPendingTasks />
                </Route>
            </Switch>
        </Router>
    );
}

export default Routes;