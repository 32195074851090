import React from 'react';
import MainPage from "./routes/main_page/main.page";
import Routes from "./routes";
import './assets/css/style.css'

function App() {
  return (
    <div className='background-color-e5e5e5'>
      <Routes />
    </div>
  );
}

export default App;
