import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'reactstrap';
import BizKeyLogo from '../../assets/icons/bizkeytech_logo.svg';
import '../../assets/css/style.css'
import BaseURL from '../../constants/baseAddress.const'
import axios from 'axios';
import UserVerticalProgressBar from "../../components/main_page/userVerticalProgressBar.component";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import StatusBox from '../../assets/icons/totalStatusBoxBgImg.svg'
import StartStatusFace from '../../assets/icons/status_faces/start.svg'
import MidleStatusFace from '../../assets/icons/status_faces/midle.svg'
import FinishStatusFace from '../../assets/icons/status_faces/finish.svg'

const MainPage = () => {
    const [internalEmployeesStatus, setInternalEmployeesStatus] = useState({});
    const [currentTime, setCurrentTime] = useState(null);
    const [currentDate, setCurrentDate] = useState(null);

    const loadData = () => {
        axios.get(`${BaseURL}/dashboard/GetInteralEmployeesStatus`)
            .then((res) => {
                if (res.data.resultFlag) {
                    setInternalEmployeesStatus(res.data.data);
                }
            })
    }

    const loadDataInIntervals = async () => {
        setInterval(() => {
            loadData()
        }, 1800000);
    }



    useEffect( () => {
        loadData();
        loadDataInIntervals();
        updateTime();
    }, [])

    const updateTime = async () => {
        setInterval(() => {
            let newDate = new Date();
            setCurrentTime(newDate.toLocaleTimeString())
            setCurrentDate(newDate.toLocaleDateString())
        }, 1000)
    }

    const calculatePercentage = (cbiz, tbiz) => {
        return (cbiz / tbiz) * 100;
    }

    const renderCurrentStatusFace = () => {
        let currentPercentage = calculatePercentage(internalEmployeesStatus.totalCurrentBiz, internalEmployeesStatus.totalTargetBiz);
        if (currentPercentage === 100) {
            return (
                <>
                    <img src={FinishStatusFace} />
                    <p className='montserrat-font-family font-size-12'>
                        <span>
                            <strong>100% </strong>
                        </span>

                        of bizes are<br/>
                        done.
                    </p>
                </>

            )
        } else if (currentPercentage >= 50) {
            return (
                <>
                    <img src={MidleStatusFace} />
                    <p className='montserrat-font-family font-size-12'>
                        <strong>>= 50%</strong><br /> of bizes are done.
                    </p>
                </>

            )
        }

        return (
            <>
                <img src={StartStatusFace} />
                <p className='montserrat-font-family font-size-12'>
                        <span>
                            <strong>Lots </strong>
                        </span>

                    of bizes are<br/>
                    waiting for<br/>
                    you.
                </p>
            </>
        )
    }


    return (
        <div >
            <Container>
                <Row>
                    <Col md={3} className='mt-5'>
                        <div className='bg-white rounded shadow-sm text-center py-4 px-4 border-radius-30'>
                            <img src={BizKeyLogo} className='bg-white' />
                        </div>

                        <div className='bg-white rounded shadow-sm text-center py-4 px-4 border-radius-30 mt-5'>
                            <div>
                                <p className='montserrat-font-family font-size-30 line-height-37 font-weight-bolder font-weight-700'>
                                    {currentTime}
                                </p>
                                <p className='montserrat-font-family font-size-16 font-weight-bolder font-weight-700'>
                                    {currentDate}
                                </p>
                            </div>
                        </div>
                        <div className='py-3 m-0 overflow-hidden border-radius-30 mt-5 text-align-center position-relative'>
                            <img className='w-100' src={StatusBox} alt={'ABASE BO AZAR'}/>
                            <div className='text-white text-align-center my-1' style={{position: "absolute", top: "70px", left: "70px"}}>
                                <SemiCircleProgressBar stroke={"#E7A03D"}
                                                       strokeWidth={5}
                                                       diameter={120}
                                                       percentage={calculatePercentage(internalEmployeesStatus.totalCurrentBiz, internalEmployeesStatus.totalTargetBiz)} />
                                {
                                    renderCurrentStatusFace()
                                }
                            </div>
                        </div>
                    </Col>
                    <Col className='mt-4'>
                        <div className='bg-white rounded shadow-sm text-center py-4 px-4 border-radius-30'>
                            <div className='d-flex flex-row justify-content-between' style={{minHeight: "700px"}}>
                                {
                                    internalEmployeesStatus?.users?.map((user) => ((
                                            <div className='d-flex flex-column justify-content-between ml-1' style={{minHeight: "550px"}} key={user.userID}>
                                                <UserVerticalProgressBar user={user} />
                                            </div>
                                        )))
                                }
                            </div>
                        </div>
                    </Col>
                    <Col md={3}>
                        {
                            internalEmployeesStatus.projects &&
                            internalEmployeesStatus.projects.map((project, ) => ((
                                <div  key={project.projectName} className='bg-white rounded shadow-sm text-center py-4 px-4 border-radius-30 mt-5' >
                                    <p className='montserrat-font-family font-size-16 font-weight-bolder font-weight-700'>
                                        {project.projectName}
                                    </p>
                                    {
                                        project.versions.map((version) => {
                                            return (
                                                <div key={version} className='shadow-sm background-color-E7A03D mt-4 rounded mx-5 '>
                                                    <p className='montserrat-font-family text-white font-size-16 py-1'>
                                                        {version}
                                                    </p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )))
                        }

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default MainPage;