import React, { useState, useEffect } from "react";
import RoutesConst from "../../constants/routes.const";
import { Link } from "react-router-dom";

const UserVerticalProgressBar = ({user, setUser}) => {
        const [boxBarBgColor, setBoxBarBgColor] = useState('#D2D2D2');
        const [progressBarBgColor, setProgressBarBgColor] = useState('#593487');
        const [currentTargetBiz, setCurrentTargetBiz] = useState(user.targetBiz);
        const [currentCountedBiz, setCurrentCountedBiz] = useState(user.countedBiz);
        const [height, setHeight] = useState(0);
        const [bgHeight, setBgHeight] = useState(0);


        useEffect(() => {
                setCurrentTargetBiz(user.targetBiz);
                setCurrentCountedBiz(user.countedBiz);
                setTimeout(() => {
                        calculatePercentage(currentCountedBiz, currentTargetBiz);
                        setBgHeight(550);
                }, [300])
        }, [user])

        const calculatePercentage = (countedBiz, targetBiz) => {
                if (countedBiz > targetBiz) {
                        const updatedCountedBiz = countedBiz - targetBiz;
                        setCurrentCountedBiz(updatedCountedBiz);
                        setBoxBarBgColor('#593487');
                        setProgressBarBgColor('#E7A03D');
                        setHeight((updatedCountedBiz / targetBiz) * 100);
                        return;
                }
                setHeight((countedBiz / targetBiz) * 100);
        }

        // const setBgColor = () => {
        //         setBoxBarBgColor('#593487');
        //         setProgressBarBgColor('#E7A03D');
        // }


        const animate = async  (next, cancel) => {
                await next({height: `${height}%`, backgroundColor: progressBarBgColor});
        }

        const storeUserId = (uID) => {
                localStorage.setItem("uID", uID)
        }


        return (
            <div className='text-center'>
                <div className='text-center'>
                        <p className='montserrat-font-family text-center mx-auto' id={'current-status-text'}>
                                {user.targetBiz.toFixed(2)}/{user.countedBiz.toFixed(2)}
                        </p>
                </div>
                    <div className="progress vertical mx-auto" style={{ height: `${bgHeight}px`, minHeight: "550px", backgroundColor: `${boxBarBgColor}`}} >
                        {/*<Spring from={{height: height, backgroundColor: progressBarBgColor}} to={[{height: `${height}%`, backgroundColor: progressBarBgColor}]} >*/}
                        {/*    {(styles) => (*/}

                                    {/*<animated.div style={styles} className="progress-bar" />*/}

                        {/*    )}*/}
                        {/*</Spring>*/}
                        <div className='progress-bar'  style={{height: `${height}%`, backgroundColor: `${progressBarBgColor}`}}/>
                    </div>
                <div>
                        <Link to={`${user.userID}`} className='text-decoration-none text-black'>
                                <div onClick={() => storeUserId(user.userID)}>
                                        <img className='rounded-circle' src={user.avatarURL} alt={'Avatar image'}/>
                                        <p className='montserrat-font-family font-size-12 line-height-15 text-dark'>
                                                {user.username}
                                        </p>
                                </div>
                        </Link>

                </div>
            </div>

        );
}


export default UserVerticalProgressBar;